import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { ClienteAhorrent } from '../models/clienteAhorrent';
import { JSONConverters } from '../models/JSONConverters';
import { Unidad } from '../models/unidad';
import { DocumentoCliente } from '../models/documentoCliente';
import { SeguimientoCliente } from '../models/seguimientoCliente1';
import { CambioEstadoUnidad } from '../models/cambioEstadoUnidad';
import { ServicioUnidad } from '../models/servicioUnidad';
import { GastoServicio } from '../models/gastoServicio';
import { ContratoAhorrent } from '../models/ContratoAhorrent';
import * as FileSaver from 'file-saver';
import * as Papa from 'papaparse';
import * as languageEncoding from 'detect-file-encoding-and-language';
import { CambioEstadoContratoAhorrent } from '../models/cambioEstadoContratoAhorrent';
import { ServicioPendienteUnidad } from '../models/ServicioPendienteUnidad';
import { MarcaAhorrent } from '../models/marcaAhorrent';
import { LineaAhorrent } from '../models/lineaAhorrent';
import { ImagenUnidad } from '../models/ImagenUnidad';
import { DocumentoUnidad } from '../models/DocumentoUnidad';
import { MultaAhorrent } from '../models/MultaAhorrent';
import { ObservacionClienteAhorrent } from '../models/ObservacionClienteAhorrent';
import { ReclamoRentaClienteAhorrent } from '../models/ReclamoRentaClienteAhorrent';
import { ReclamoGeneralClienteAhorrent } from '../models/ReclamoGeneralClienteAhorrent';
import { EncuestaClienteAhorrent } from '../models/EncuestaClienteAhorrent';
import * as moment from 'moment';
import { PendienteUnidad } from '../models/pendienteUnidad';
import { LavadoEspecial } from '../models/LavadoEspecial';

@Injectable()
export class AhorrentService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = async function (file) {
            let infoFile = function (file: File) {
                return new Promise((resolve, reject) => {
                    languageEncoding(file).then((fileInfo) => resolve(fileInfo));
                });
            } 

            // Obtener resultados del CSV
            let info: any = await infoFile(file);

            return new Promise(function (complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    encoding: info.encoding,
                    complete,
                    error
                });
            });
        };
     }

    // * * * Unidades * * * 
    async obtenerTodasUnidades() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.unidadDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerUnidadesFiltradasContrato(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/search?' + params;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.records.length; i++) {
                var element = json.records[i];
                var registro = this.jsonConverters.unidadDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.unidadDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTablaMontosUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/tablaMontos/' + id;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { registro: json } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearUnidad(registro: Unidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Unidad creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarUnidad(registro: Unidad, ContratoAhorrentIdActualiza: number = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL;
            registro.ContratoAhorrentIdActualiza = ContratoAhorrentIdActualiza;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Unidad actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarUnidadRelocation(idsUnidades: number[], ubicacion: string, ubicacionActual: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/relocation';
            const res = await this.connection.putRequest(url, { idsUnidades: idsUnidades, ubicacion: ubicacion, ubicacionActual: ubicacionActual });
            return { error: null, data: { mensaje: 'Unidad Cambio Ubicación actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarUnidad(UnidadId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/id/' + UnidadId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Unidad eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }


    // * * * Clientes Ahorrent * * * 
    async obtenerTodasClientes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.clienteAhorrentDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerClienteAhorrentPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.clienteAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerClienteAhorrentPorNIT(NIT: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL + '/nit/' + NIT;
            var json = await this.connection.getRequest(url, false);
            var registro = this.jsonConverters.clienteAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'El NIT no fue encontrado.');
        }
    }

    async obtenerClienteAhorrentPorNumeroContrato(numeroContrato: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL + '/numeroContrato/' + numeroContrato;
            var json = await this.connection.getRequest(url, false);
            var registro = this.jsonConverters.clienteAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'El NIT no fue encontrado.');
        }
    }

    // Crear
    public async crearClienteAhorrent(registro: ClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cliente Ahorrent creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarClienteAhorrent(registro: ClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Cliente Ahorrent actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarClienteAhorrent(ClienteAhorrentId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL + '/id/' + ClienteAhorrentId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Cliente Ahorrent eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Contratos * * * 
    async obtenerTodosContratosAhorrent(params:string = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL;
            if(params) url += '?' + params;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.contratoAhorrentDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerContratoAhorrentPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.contratoAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Duplicar
    public async duplicarContratoAhorrent(ContratoAhorrentId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/duplicar';
            const res = await this.connection.postRequest(url, { idOriginal: ContratoAhorrentId });
            return { error: null, data: { mensaje: 'Contrato Ahorrent duplicado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async convertirAContratoAhorrent(ContratoAhorrentId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/convertirAContrato';
            const res = await this.connection.postRequest(url, { idOriginal: ContratoAhorrentId });
            return { error: null, data: { mensaje: 'Reserva actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear
    public async crearContratoAhorrent(registro: ContratoAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Contrato Ahorrent creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarContratoAhorrent(registro: ContratoAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Contrato Ahorrent actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarContratoAhorrent(ContratoAhorrentId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/id/' + ContratoAhorrentId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Contrato Ahorrent eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async obtenerCalendarioContratos(fecha: string) {
        try {
            /*URL para obtener todos los movimientos de cuenta relacionados
            a un proyecto y a una unidad habitacional , que no estén pagados y que no sean abonos*/
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/calendario' + '?fecha=' + fecha;

            var json = await this.connection.getRequest(url);

            var registros = [];
            if(json.registros && Array.isArray(json.registros) && json.registros){
                for (let registro of json.registros) {
                    registros.push(this.jsonConverters.contratoAhorrentDeJSON(registro));
                }
            }
            
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los movimientos.');
        }
    }

    // Actualizar
    public async asignarLavadorContratoAhorrent(contrato: ContratoAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/asignar-lavador';
            const res = await this.connection.putRequest(url, contrato);
            return { error: null, data: { mensaje: 'Lavador asignado correctamente', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
b
    // Actualizar
    public async marcarLavadoContratoAhorrent(contratoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/marcar-lavado';
            const res = await this.connection.putRequest(url, { id: contratoId });
            return { error: null, data: { mensaje: 'Contrato Ahorrent actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async aprobarLavadoEspecial(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lavadosEspecialesURL + '/aprobar';
            const res = await this.connection.postRequest(url, { id: id });
            return { error: null, data: { mensaje: 'Registro aprobado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async listoLavadoEspecial(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lavadosEspecialesURL + '/lavado';
            const res = await this.connection.postRequest(url, { id: id });
            return { error: null, data: { mensaje: 'Registro marcado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    //Configuracion

    public async obtenerConfiguracion() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionAhorrentURL;
            const res = await this.connection.getRequest(url);
            var registro = this.jsonConverters.configuracionAhorrentDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarConfiguracion(registro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Configuracion Ahorrent actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    /***  Documentos Cliente  ****/
    async obtenerDocumentoClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosClientesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.documentoClienteDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
    
    // Crear
    public async crearDocumentoCliente(registro: DocumentoCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosClientesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Documento Cliente creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarDocumentoCliente(registro: DocumentoCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosClientesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Documento Cliente actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Eliminar
    public async eliminarDocumentoCliente(DocumentoClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosClientesURL + '/id/' + DocumentoClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Documento Cliente eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    /***  Seguimientos Cliente  ****/
    async obtenerSeguimientoClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.seguimientosClientesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.seguimientoClienteDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearSeguimientoCliente(registro: SeguimientoCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.seguimientosClientesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Seguimiento Cliente creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarSeguimientoCliente(registro: SeguimientoCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.seguimientosClientesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Seguimiento Cliente actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarSeguimientoCliente(SeguimientoClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.seguimientosClientesURL + '/id/' + SeguimientoClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Seguimiento Cliente eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //* * Cambiar Estado Unidad * * * *
    // Crear
    public async crearCambioEstadoUnidad(registro: CambioEstadoUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambiosEstadosUnidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cambio de estado creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    //* * Cambiar Estado Contrato * * * *
    // Crear
    public async crearCambioEstadoContrato(registro: CambioEstadoContratoAhorrent, nivelGasolina: any) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cambiosEstadosContratosURL;
            const res = await this.connection.postRequest(url, {registro: registro, nivelGasolina: nivelGasolina});
            return { error: null, data: { mensaje: 'Cambio de estado creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    /***  Servicios Unidad  ****/
    async obtenerServicioUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosUnidadesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.servicioUnidadDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearServicioUnidad(registro: ServicioUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosUnidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Servicio creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarServicioUnidad(registro: ServicioUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosUnidadesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Servicio actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarServicioUnidad(ServicioUnidadId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosUnidadesURL + '/id/' + ServicioUnidadId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Servicio eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Actualizar
    public async finalizarServicioUnidad(registro: ServicioUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosUnidadesURL + '/finalizar';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Servicio finalizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    /***  Gastos Servicios  ****/
    async obtenerGastoServicioPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gastosServiciosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.gastoServicioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearGastoServicio(registro: GastoServicio) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gastosServiciosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Gasto Servicio creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarGastoServicio(registro: GastoServicio) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gastosServiciosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Gasto Servicio actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarGastoServicio(GastoServicioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gastosServiciosURL + '/id/' + GastoServicioId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Gasto Servicio eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async guardarArchivoEnServidor(archivo: File) {
        try {
            var url = this.apiUrls.uploadURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if (!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    // * * * Descuentos * * * //
    async obtenerTodosLosDescuentos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.otroDescuentoURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.otroDescuentoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los descuentos.');
        }
    }

    async descargarInspeccionContrato(ContratoId: number, fileName: string, tipo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + `/inspeccion-${tipo}/${ContratoId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Inspeccion de contrato descarga con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarContrato(ContratoId: number, fileName: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + `/descargar/${ContratoId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Contrato descargado con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarReserva(ContratoId: number, fileName: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + `/descargar-reserva/${ContratoId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Reserva descargada con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Correo * * *
    async enviarCorreo(correos: string, contratoId: number, tipo: string) {
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + `/inspeccion-enviar-${tipo}`;

            let enviar = {
                correos: correos,
                ContratoId: contratoId
            };

            const res = await this.connection.postRequest(url, enviar);
            return { error: null, data: { mensaje: (['general', 'reserva'].includes(tipo) ? 'PDF enviado con éxito' : 'Inspección enviada con éxito' ), result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Traslape fechas * * *
    async traslapeFechas(fechaInicio: string, fechaFin: string, ContratoIdIgnorar = null, UnidadId: number, horaSalida, horaEntrada){
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/traslape-fechas';

            url += `?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;
            url += ContratoIdIgnorar ? `&ContratoIdIgnorar=${ContratoIdIgnorar}` : '';
            url += '&UnidadId=' + UnidadId;
            url += '&horaSalida=' + horaSalida;
            url += '&horaEntrada=' + horaEntrada;

            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.contratoAhorrentDeJSON(element));
            }

            return { error: null, data: { registros: registros } };

        } catch (error) {
            
        }
    }

    // Reporte de unidades
    async descargarListadoUnidadesExcel(nombre: string, detalle:boolean = false, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL
            url+= !detalle? '/reporte-unidades-excel?': '/reporte-unidades-excel-detalle?';
            url+= '&reporte=true' + params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: response };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }

    async reporteListadoUnidades(detalle:boolean = false, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL
            url+= detalle? '/reporte-unidades-detalle?': '/reporte-unidades?';
            url+= '&reporte=true' + params;

            var json = await this.connection.getRequest(url);
            return { error: null, data: { registros: json } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }

    async reporteListadoUnidadesv2(detalle:boolean = false, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL
            url+= detalle? '/reporte-unidades-detalle-v2?': '/reporte-unidades-v2?';
            url+= '&reporte=true' + params;

            var json = await this.connection.getRequest(url);
            return { error: null, data: { unidadesConDias: json.unidadesConDias, dias: json.dias, totales: json.totales } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }

    async reporteListadoUnidadesv3(detalle:boolean = false, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL
            url+= detalle? '/reporte-unidades-detalle-v3?': '/reporte-unidades-v3?';
            url+= '&reporte=true' + params;

            var json = await this.connection.getRequest(url);
            return { error: null, data: { unidadesGrupos: json.unidadesGrupos, dias: json.dias, totales: json.totales } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);;
        }
    }

    async obtenerReporte(fechaInicioParam: any, fechaFinParam: any, paramsExtras: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
            if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (paramsExtras) url += paramsExtras;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
          
    /***  Servicios Pendientes Unidad  ****/
    //Leer
    async obtenerServicioPendienteUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosPendientesUnidadesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.servicioPendienteUnidadDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearServicioPendienteUnidad(registro: ServicioPendienteUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosPendientesUnidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Servicio creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarServicioPendienteUnidad(registro: ServicioPendienteUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosPendientesUnidadesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Servicio actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarServicioPendienteUnidad(ServicioPendienteUnidadId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosPendientesUnidadesURL + '/id/' + ServicioPendienteUnidadId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Servicio eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
    //Todos
    async obtenerTodosLosServiciosPendientesUnidades(params:string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosPendientesUnidadesURL + '/';
            if(params) url+= '?' + params;
            var json = await this.connection.getRequest(url);
    
            var registros = [];
            for (let element of json) {
                registros.push(this.jsonConverters.servicioPendienteUnidadDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los descuentos.');
        }
    }

    // Actualizar datos
    public async actualizarServiciosPendientesUnidades(registros:any,id:number) {
        try {
            let data = JSON.stringify(registros);
            var url = this.apiUrls.baseURL + this.apiUrls.serviciosPendientesUnidadesURL + '/actualizar-registros';
            const res = await this.connection.postRequest(url,{data: data, ServicioUnidadId: id} );
            return { error: null, data: { mensaje: 'Servicio actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    //// * * * Carga Masiva * * * 
    // * * * * * Generar plantilla * * * * *
    public async generarPlantillaClienteAhorrents(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL + '/generacion-plantilla';
            var res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
          
    //* * * Marca y Linea * * *
    async obtenerTodasLasMarcasVehiculo() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaAhorrentURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.marcaAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las marcas de los vehiculos.');
        }
    }

    async obtenerMarcaVehiculoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.marcaAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMarcaVehiculo(registro: MarcaAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Marca de vehiculo creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarMarcaVehiculo(registro: MarcaAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Marca de vehiculo actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Lectura del archivo Excel * * * * *
    async lecturaArchivoClienteAhorrents(archivo, columnas ) {
        try {
             // Obtener resultados del Excel
             var resArchivo = await Papa.parsePromise(archivo);
             if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;

            var resultadosFinales:any[] = [];
            resultados.forEach(element => {
                let cliente = new ClienteAhorrent (-1,element['nombres'], element['apellidos'], `${element['nombres']} ${element['apellidos']}`,
                element['email'], element['telefono'], null,true,false,null,element['dpi'],element['nit'], element['direccion'],
                null,null,false,this.apiUrls.tipoCliente[0].id,element['categoria'], element['fecha de nacimiento (dd/mm/aaaa)'], element['nacionalidad'],null );

                resultadosFinales.push(cliente);
            })

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo correctamente.',
            };
        } catch (error) {
            return { error: true, mensaje: error.message };
        }

    }

    async analizarRegistrosClienteAhorrents(registros: ClienteAhorrent[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL + '/analisis-registros-clientes';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var registrosAIgnorar = res.registrosIgnorados;
            var errores = res.errores;
            return { error: null, data: {
                mensaje: 'Análisis de registros finalizado correctamente',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                registrosAIgnorar: registrosAIgnorar,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Linea de vehiculos * * * //
    async obtenerLineaVehiculoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.lineaAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async ObtenerTodasLasLineas(MarcaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaAhorrentURL;
            url += '?&MarcaVehiculoId=' + MarcaId;
            url += '&usarTransparenciaPrecios=0';
            var json = await this.connection.getRequest(url, false);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.lineaAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las líneas.');
        }
    }

    async ObtenerTodasLasLineasExistentes(auth: boolean = true, usarTransparenciaPrecios: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaAhorrentURL;
            if(usarTransparenciaPrecios == true) url += '?&usarTransparenciaPrecios=1';
            else url += '?&usarTransparenciaPrecios=0';
            var json = await this.connection.getRequest(url,auth);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.lineaAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las líneas.');
        }
    }

    // Crear
    public async crearLineaVehiculo(registro: LineaAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Linea de vehiculo creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarLineaVehiculo(registro: LineaAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Linea de vehiculo actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirRegistrosClienteAhorrents(registros: ClienteAhorrent[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.clientesAhorrentURL + '/carga-clientes';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Carga de clientes finalizado con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarLineaVehiculo(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineaAhorrentURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Linea eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async eliminarMarcaVehiculo(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcaAhorrentURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Marca eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async enviarCorreoMantenimiento(contratoId: number, color: string) {
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + `/enviar-aviso-mantenimiento?&ContratoAhorrentId=${contratoId}&color=${color}`;

            const res = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Inspección enviada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async enviarCorreoEnVenta(UnidadId: number) {
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + `/enviar-aviso-en-venta?&UnidadId=${UnidadId}`;

            const res = await this.connection.getRequest(url);
            return { error: null, data: { mensaje: 'Correos enviados', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async verificarContratos(UnidadId: number, fecha?: string) {
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + `/verificar-contratos/` + UnidadId;
            if (fecha) url += `?&fecha=${fecha}`;

            const res = await this.connection.getRequest(url);

            let registros = [];
            for (const el of res) {
                let registro = this.jsonConverters.contratoAhorrentDeJSON(el);
                registros.push(registro);
            }

            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * DocumentosUnidades * * *
    async obtenerTodosDocumentosUnidad(UnidadId = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosUnidadesURL;
            if(UnidadId) url += `?UnidadId=${UnidadId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.documentoUnidadDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerDocumentoUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosUnidadesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.documentoUnidadDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDocumentoUnidad(registro: DocumentoUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosUnidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Documento de unidad actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarDocumentoUnidad(registro: DocumentoUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosUnidadesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Documento de unidad actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDocumentoUnidad(DocumentoUnidadId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosUnidadesURL + '/id/' + DocumentoUnidadId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Documento de unidad eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * ImagenesUnidades * * *
    async obtenerTodosImagenUnidad(UnidadId = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.imagenesUnidadesURL;
            if(UnidadId) url += `?UnidadId=${UnidadId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.imagenUnidadDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerImagenUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.imagenesUnidadesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.imagenUnidadDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearImagenUnidad(registro: ImagenUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.imagenesUnidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Imagen de unidad actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarImagenUnidad(registro: ImagenUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.imagenesUnidadesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Imagen de unidad actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarImagenUnidad(ImagenUnidadId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.imagenesUnidadesURL + '/id/' + ImagenUnidadId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Imagen de unidad eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Pendientes unidades * * *
    async obtenerTodosPendienteUnidad(UnidadId = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.pendientesUnidadesURL;
            if(UnidadId) url += `?UnidadId=${UnidadId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.pendienteUnidadDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerPendienteUnidadPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.pendientesUnidadesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.pendienteUnidadDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearPendienteUnidad(registro: PendienteUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.pendientesUnidadesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Pendiente de unidad actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarPendienteUnidad(registro: PendienteUnidad) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.pendientesUnidadesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Pendiente de unidad actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPendienteUnidad(PendienteUnidadId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.pendientesUnidadesURL + '/id/' + PendienteUnidadId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Pendiente de unidad eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Lavado especial * * *
    async obtenerTodosLavadoEspecial(UnidadId = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lavadosEspecialesURL;
            if(UnidadId) url += `?UnidadId=${UnidadId}`;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.lavadoEspecialDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerLavadoEspecialPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lavadosEspecialesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.lavadoEspecialDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearLavadoEspecial(registro: LavadoEspecial) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lavadosEspecialesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarLavadoEspecial(registro: LavadoEspecial) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lavadosEspecialesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarLavadoEspecial(LavadoEspecialId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lavadosEspecialesURL + '/id/' + LavadoEspecialId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //* * * MultaAhorrent * * *
    async obtenerTodasLasMultasAhorrent() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.multasAhorrentURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.multaAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las multas Ahorrent.');
        }
    }

    async obtenerMultaAhorrentPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.multasAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.multaAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear multa ahorrent
    public async crearMultaAhorrent(registro: MultaAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.multasAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Multa Ahorrent creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar multa ahorrent
    public async actualizarMultaAhorrent(registro: MultaAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.multasAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Multa Ahorrent actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar multa ahorrent
    public async eliminarMultaAhorrent(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.multasAhorrentURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Multa Ahorrent eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //observaciones clientes ahorrent
    async obtenerObservacionesClientesTodos(){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.observacionesClientesAhorrentURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.observacionClienteAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las observaciones de clientes Ahorrent.');
        }
    }


    /***  Seguimientos Cliente  ****/
    async obtenerObservacionClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.observacionesClientesAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.observacionClienteAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearObservacionCliente(registro: ObservacionClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.observacionesClientesAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Observación cliente creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarObservacionCliente(registro: ObservacionClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.observacionesClientesAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Observación Cliente actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarObservacionCliente(ObservacionClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.observacionesClientesAhorrentURL + '/id/' + ObservacionClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Observación Cliente eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //reclamosRentas
    async obtenerReclamosRentasTodos(){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosRentaClienteAhorrentURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.reclamoRentaClienteAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los reclamos de rentas.');
        }

    }

    async obtenerReclamoRentaClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosRentaClienteAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.reclamoRentaClienteAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearReclamoRentaCliente(registro: ReclamoRentaClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosRentaClienteAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Reclamo renta creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }

    }

    // Actualizar
    public async actualizarReclamoRentaCliente(registro: ReclamoRentaClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosRentaClienteAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Reclamo renta actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }

    }

    // Eliminar
    public async eliminarReclamoRentaCliente(reclamoRentaClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosRentaClienteAhorrentURL + '/id/' + reclamoRentaClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Reclamo renta eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerContratosAhorrentPorCliente(ClienteAhorrentId){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/cliente/' + ClienteAhorrentId;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.contratoAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los contratos.');
        }

    }

    //ReclamoGeneralClienteAhorrent
    async obtenerReclamosGeneralesTodos(){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosGeneralesClienteAhorrentURL;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.reclamoGeneralClienteAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los reclamos generales.');
        }

    }


    async obtenerReclamoGeneralClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosGeneralesClienteAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.reclamoGeneralClienteAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearReclamoGeneralCliente(registro: ReclamoGeneralClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosGeneralesClienteAhorrentURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Reclamo general creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }

    }

    // Actualizar
    public async actualizarReclamoGeneralCliente(registro: ReclamoGeneralClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosGeneralesClienteAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Reclamo general actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }

    }

    // Eliminar
    public async eliminarReclamoGeneralCliente(reclamoGeneralClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosGeneralesClienteAhorrentURL + '/id/' + reclamoGeneralClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Reclamo general eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }


    async obtenerDataContratos(idUsuario: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/resumenContratos?';
            url += '&porClienteAhorrentId=' + idUsuario;

            var json = await this.connection.getRequest(url);
            var data = {
                totalContratosCerrados:     json.data.totalContratosCerrados,
                contratosReservados:        json.data.contratosReservados,
                contratosAbiertos:          json.data.contratosAbiertos,
                contratosCerrados:          json.data.contratosCerrados,             
            };
            
            return { error: null, data: data };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener el resumen de inicio.');
        }
    }

    // Encuestas
    async obtenerTodasLasEncuestaClienteAhorrent() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.encuestaClienteAhorrentDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener EncuestaClienteAhorrent.');
        }
    }

    async obtenerEncuestaClienteAhorrentPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.encuestaClienteAhorrentDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear EncuestaCliente ahorrent
    public async crearEncuestaClienteAhorrent(registro: EncuestaClienteAhorrent, logeado: boolean = true) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL;
            if(!logeado) url += '/publica';
            const res = await this.connection.postRequest(url, registro, logeado);
            return { error: null, data: { mensaje: logeado?'Encuesta enviada con éxito':'Será redirigido a la encuesta', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar EncuestaCliente ahorrent
    public async actualizarEncuestaClienteAhorrent(registro: EncuestaClienteAhorrent) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'EncuestaClienteAhorrent actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar EncuestaCliente ahorrent
    public async eliminarEncuestaClienteAhorrent(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'EncuestaClienteAhorrent eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async obtenerConfiguracionPublico() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.configuracionAhorrentURL + '/encuesta-individual-publica';
            const res = await this.connection.getRequest(url, false);
            var registro = this.jsonConverters.configuracionAhorrentDeJSON(res.result);
            return { error: null, data: { result: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelEncuestas(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    async descargarEncuesta(EncuestaId: number, fileName: string, tipo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL + `/descargarPDFEncuesta/${tipo}/${EncuestaId}`;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = `${fileName}.pdf`;
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Inspeccion de contrato descarga con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerReporteEncuestas(fechaInicioParam: any, fechaFinParam: any, params = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.encuestaClienteAhorrentURL + '/reporte?';
            if (fechaInicioParam) url += '&start=' + fechaInicioParam;
                if (fechaFinParam) url += '&finish=' + fechaFinParam;
            if (params) url += params;
            var json = await this.connection.getRequest(url);
            var registro = json;
            return { error: null, data: { registro: registro.data } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    //// * * * Carga Masiva * * * 
    // * * * * * Generar plantilla * * * * *
    public async generarPlantillaDepreciacion(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/generacion-plantilla-depreciacion';
            var res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Lectura del archivo Excel * * * * *
    async lecturaArchivoDepreciacion(archivo, columnas ) {
        try {
             // Obtener resultados del Excel
             var resArchivo = await Papa.parsePromise(archivo);
             if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;

            var resultadosFinales:any[] = [];
            resultados.forEach(element => {
                let depreciacion = {
                    numero: element['numero de unidad'],
                    fechaAdquisicion: element['fecha de adquisicion'],
                    precioAdquisicionSinIva: element['precio de adquisicion sin iva'],
                    mesesDepreciar: element['meses a depreciar'],
                }

                resultadosFinales.push(depreciacion);
            })

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo correctamente.',
            };
        } catch (error) {
            return { error: true, mensaje: error.message };
        }

    }

    async analizarRegistrosDepreciacion(registros: any[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/analisis-registros-depreciacion';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var registrosAIgnorar = res.registrosIgnorados;
            var errores = res.errores;
            return { error: null, data: {
                mensaje: 'Análisis de registros finalizado correctamente',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                registrosAIgnorar: registrosAIgnorar,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirRegistrosDepreciacion(registros: any[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/carga-depreciacion';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Carga de datos depreciación finalizado con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async generarPlantillaUnidades(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/generacion-plantilla-unidades';
            var res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Lectura del archivo Excel * * * * *
    async lecturaArchivoUnidades(archivo, columnas ) {
        try {
             // Obtener resultados del Excel
             var resArchivo = await Papa.parsePromise(archivo);
             if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;

            var resultadosFinales:any[] = [];
            resultados.forEach(element => {
                let unidad = {
                    numero: element['numero de unidad'],
                    numeroPuertas: element['numero de puertas'],
                    cilindros: element['cilindros'],
                    centimetrosCubicos: element['centimetros cubicos'],
                    tonelaje: element['tonelaje'],
                    anchoUnidad: element['ancho de la unidad'],
                    largoUnidad: element['largo de la unidad'],
                    altoUnidad: element['alto de la unidad'],
                    anchoFurgon: element['ancho del furgon'],
                    largoFurgon: element['largo del furgon'],
                    altoFurgon: element['alto del furgon'],
                }

                resultadosFinales.push(unidad);
            })

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo correctamente.',
            };
        } catch (error) {
            return { error: true, mensaje: error.message };
        }

    }

    async analizarRegistrosUnidades(registros: any[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/analisis-registros-unidades';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var registrosAIgnorar = res.registrosIgnorados;
            var errores = res.errores;
            return { error: null, data: {
                mensaje: 'Análisis de registros finalizado correctamente',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                registrosAIgnorar: registrosAIgnorar,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirRegistrosUnidades(registros: any[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/carga-unidades';
            var data = {
                registros: registros,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Carga de datos de unidades finalizado con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    calcularAniosPorcentajes(): number[] {
        let aniosLote = [];
        const anioActual = moment().year();
        const anioFinal = anioActual + 3;

        for (let i = 2022; i <= anioFinal; i++) {
            aniosLote.push(i);
        }
        return aniosLote;
    }

    public async descargarExcelCambiosFechas(nombre: string, params:string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.cambiosFechasContratosAhorrentURL + '/descargar-excel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async obtenerDashboardAgentes(paramsExtras: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/dashboard-agentes?';
            if (paramsExtras) url += paramsExtras;
            var json = await this.connection.getRequest(url);
            var result = json;
            return { error: null, data: { result: result } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerDashboardAnalisisRentas(paramsExtras: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/dashboard-analisis-rentas?';
            if (paramsExtras) url += paramsExtras;
            var json = await this.connection.getRequest(url);
            var result = json;
            return { error: null, data: { result: result } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerReporteEficiencia(meses: number[], anio: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/resumen-mensual-eficiencia?';
            url += '&meses=' + meses.join(',');
            url += '&anio=' + anio;
            var json = await this.connection.getRequest(url);
            var result = json;
            return { error: null, data: { result: result } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerReporteLavado(meses: number[], anio: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/resumen-mensual-lavado?';
            url += '&meses=' + meses.join(',');
            url += '&anio=' + anio;
            var json = await this.connection.getRequest(url);
            var result = json;
            return { error: null, data: { result: result } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerDashboardDescuento(paramsExtras: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.contratosAhorrentURL + '/reporte-decuento?';
            if (paramsExtras) url += paramsExtras;
            var json = await this.connection.getRequest(url);
            var result = json;
            return { error: null, data: { result: result } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async subirImagenUnidades(url: string, MarcaId: number, Linea: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.unidadesURL + '/carga-imagen-unidades';
            var data = {
                url: url,
                MarcaId: MarcaId,
                Linea: Linea,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Carga de datos de unidades finalizado con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}