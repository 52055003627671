import { Requisicion } from "./Requisicion";

export class DetalleRequisicion {
    constructor(
        public id:                              number,
        public cantidad:                        number,
        public codigo:                          string,
        public descripcion:                     string,
        public valorFOB:                        number,
        public envio:                           string,
        public pais:                            string,
        public entregaDias:                     string,
        public fecha:                           string,
        public poliza:                          string,
        public ingreso:                         boolean,
        public landedCost:                      number,
        public precioUnitario:                  number,
        public precioTotal:                     number,
        public autorizado:                      boolean,
        public codigoVelocidad:                 string,
        public pedidoSistema:                   string,
        public pedidoFabrica:                   string,
        public comentario:                      string,
        public precioSinIVA:                    number,
        public valorQuetzales:                  number,
        public factorGanancia:                  string,
        public precioConIVA:                    number,
        public descuento:                       number,
        public total:                           number,
        public despachado:                      boolean,
        public backorder:                       boolean,
        public placa:                           string,
        public chasis:                          string,
        public vehiculo:                        string,
        public modelo:                          string,
        public sucursal:                        string,
        public observacionVehiculo:             string,
        public facturado:                       boolean,
        public fechaFacturado:                  string,
        public fechaEmbarcado:                  string,
        public embarcado:                       boolean,
        public canibalizado:                    boolean,

        // Relaciones
        public RequisicionId:                   number,
    ){}

    // Relaciones
    requisicion: Requisicion;

    fechaFormato: string;
    fechaDate: Date;
}